// Get the clock container element
const clock = document.querySelector('.clock');

// Set the clock to the current time
// Set the clock to the current time in the Pacific time zone
function updateClock() {
  const pacificTime = new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
  const date = new Date(pacificTime);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let amPm = 'am';
  if (hours >= 12) {
    amPm = 'pm';
    hours = hours % 12;
    if (hours === 0) {
      hours = 12;
    }
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  clock.innerHTML = `${hours}:${minutes} <span id="am-pm">${amPm} pacific</span>`;
}

updateClock();

// Update the clock every second
setInterval(updateClock, 1000);
